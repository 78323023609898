@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
@import './partials/header/header';
@import './partials/footer/footer';
@import './partials/swap';
@import './partials/farms';
@import './partials/poolCard';
@import './partials/xplenty';
@import './theme';

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.p-90 {
  padding: 0 90px;
}

.p-26 {
  padding: 0 26px;
}

.pb-20 {
  padding-bottom: 20px;
}
.status-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  color: var(--theme-floater-text);
}
.view-tezos {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  color: var(--theme-primary-button-bg);
  margin-top: 2px;
}

.loading-data-wrapper {
  position: fixed;
  bottom: 60px;
  right: 72px;
}

.loader-message-wrapper {
  position: fixed;
  bottom: 60px;
  right: 40px;
  padding: 20px;
  background: var(--theme-flash-bg);
  border: 0.5px solid var(--theme-flash-border);
  box-sizing: border-box;
  box-shadow: 0px 11px 44px rgba(16, 3, 34, 0.18);
  width: 390px;
  height: 93px;
  border-radius: 8px;
  z-index: 1000;
  @media screen and (max-width: 991px) {
    left: 10px;
    right: 40px;
    top: 736px;
  }

  > span {
    vertical-align: middle;
  }
}

.content-hide {
  display: none;
}

.navbar-toggler {
  border: none;
}

.modal-backdrop {
  background: var(--theme-bg-1);
  //filter: blur(8px);
}

.loader-btn {
  > span {
    display: inline-block;
  }
}
#container {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 95px;
  height: 95px;
}
#transactionSubmitted {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 153px;
  height: 153px;
}
$h1-font-sizes: (
  null: 38px,
  480px: 40px,
  640px: 42px,
  1024px: 60px,
  1400px: 76px,
);

$h3-font-sizes: (
  null: 30px,
  480px: 36px,
  640px: 42px,
  1024px: 48px,
);

@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
    } @else {
      @media screen and (min-width: $fs-breakpoint) {
        font-size: $fs-font-size;
      }
    }
  }
}

h1 {
  @include font-size($h1-font-sizes);
}

.tooltip-inner {
  background: var(--theme-tooltip-bg);
  color: var(--theme-tooltip-text);
  opacity: 1 !important;
  max-width: 324px !important;
  // position: absolute;
  // top: -20px;
  // left: -20px;
}

.tooltip > .arrow {
  opacity: 0;
}

.shimmer {
  background: var(--theme-bg-2);
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  animation: 3s placeholderShimmer infinite;
  color: transparent;
  border-radius: 6px;
}
.shimmer-circle {
  background: var(--theme-bg-2);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  animation: 3s placeholderShimmer infinite;
  color: transparent;
}

@keyframes placeholderShimmer {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

body {
  color: var(--theme-text-1);
  background-color: var(--theme-bg-1);
  .modal {
    z-index: 10000 !important;
  }
}

hr {
  border-top: 1px solid var(--theme-border);
}

.plenty-tooltip-default {
  font-size: 12px;
  line-height: 13px;
}

.switchTooltip .tooltip-inner {
  background-color: #282828;
  color: #fff;
}
