.leftToRightFadeInAnimation {
  &-4 {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }
}

@keyframes fadein {
  from {
    opacity: 0.4;
    transform: translateX(-15px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0.4;
    transform: translateX(-15px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
.rightToLeftFadeInAnimation {
  &-4-stableswap {
    -webkit-animation: rightToleftF 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: rightToleftF 1s; /* Firefox < 16 */
    -ms-animation: rightToleftF 1s; /* Internet Explorer */
    -o-animation: rightToleftF 1s; /* Opera < 12.1 */
    animation: rightToleftF 1s;
  }
  &-4-floater {
    -webkit-animation: rightToleftFloater 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: rightToleftFloater 2s; /* Firefox < 16 */
    -ms-animation: rightToleftFloater 2s; /* Internet Explorer */
    -o-animation: rightToleftFloater 2s; /* Opera < 12.1 */
    animation: rightToleftFloater 2s;
  }
}

.bottomToTopFadeInAnimation {
  &-4-floater {
    -webkit-animation: bottomToTopFloater 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: bottomToTopFloater 1s; /* Firefox < 16 */
    -ms-animation: bottomToTopFloater 1s; /* Internet Explorer */
    -o-animation: bottomToTopFloater 1s; /* Opera < 12.1 */
    animation: bottomToTopFloater 1s;
  }
}
.topToBottomFadeInAnimation {
  &-4-floater {
    -webkit-animation: topToBottomFloater 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: topToBottomFloater 1s; /* Firefox < 16 */
    -ms-animation: topToBottomFloater 1s; /* Internet Explorer */
    -o-animation: topToBottomFloater 1s; /* Opera < 12.1 */
    animation: topToBottomFloater 1s;
  }
  &-1-header {
    -webkit-animation: topToBottomFloater 0.1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: topToBottomFloater 0.1s; /* Firefox < 16 */
    -ms-animation: topToBottomFloater 0.1s; /* Internet Explorer */
    -o-animation: topToBottomFloater 0.1s; /* Opera < 12.1 */
    animation: topToBottomFloater 0.1s;
  }
}

@keyframes rightToleftF {
  from {
    opacity: 0.4;
    transform: translateX(15px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes rightToleftFloater {
  from {
    opacity: 0.4;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes bottomToTopFloater {
  from {
    opacity: 0.4;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes topToBottomFloater {
  from {
    opacity: 0.2;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* Firefox < 16 */
@-moz-keyframes rightToleftF {
  from {
    opacity: 1;
    transform: translateX(15px);
  }
  to {
    opacity: 0.4;
    transform: translateX(0px);
  }
}

.lds-default {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: var(--theme-primary-button-bg);
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.loader-button {
  border: 2.40218px solid var(--theme-loader-border);
  border-radius: 50%;
  border-top: 2.40218px solid var(--theme-loader-border-top);
  width: 21.99px;
  height: 21.99px;
  -webkit-animation: spin 0.7s linear infinite; /* Safari */
  animation: spin 0.7s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.scale-in-animation {
  animation: scale-in 0.2s;
  -webkit-animation: scale-in 0.2s;
  &-confirm-swap {
    animation: scale-in 0.3s;
    -webkit-animation: scale-in 0.3s;
  }
}

@keyframes scale-in {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}

@-webkit-keyframes scale-in {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}
