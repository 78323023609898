.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-secondary-1);
  border-radius: 28px;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 5px;
    bottom: 4px;
    border-radius: 50%;
    background-color: var(--theme-primary-gradient-1);
    transition: 0.4s;
  }
}

input:checked + .slider:before {
  transform: translateX(22px);
  transition: 0.4s;
}

.label {
  line-height: 28px;
  color: var(--theme-text-3);
  display: none;

  &.active {
    display: inline;
    color: var(--theme-text-1);
  }
}
