:root.light {
  --theme-primary-1: #6202ef;
  --theme-primary-2: #d0bde6;
  --theme-secondary-1: #e3d1ff;
  --theme-primary-gradient-1: #7028e4;
  --theme-primary-gradient-2: #e5b2ca;
  --theme-secondary-gradient-1: #f5f0fd;
  --theme-secondary-gradient-2: #fdfafb;
  --theme-secondary-gradient: linear-gradient(
    90deg,
    var(--theme-secondary-gradient-1) -47.72%,
    var(--theme-secondary-gradient-2) 100%
  );
  --theme-card-bg: #ffffff;
  --theme-input-bg: #f9fafa;
  --theme-text-1: #4e5d78;
  --theme-text-2: #25213b;
  --theme-text-inactive: #b0b7c3;
  --theme-text-3: #8a94a6;
  --theme-text-4: #b0b7c3;
  --theme-text-5: #8a94a6;
  --theme-bg-1: #f9fafa;
  --theme-bg-2: #f3f3f3;
  --theme-bg-3: #fafbfc;
  --theme-bg-4: #ffffff;
  --theme-border: #f1f1f1;
  --theme-border-global: rgba(0, 0, 0, 0.08);
  --theme-border-light: #d5d5d5;
  --theme-default-btn: #5c0fae;
  --theme-plenty-badge-bg: rgba(92, 15, 174, 0.16);
  --theme-plenty-badge-text: #6202ef;
  --theme-quipuswap-badge-bg: rgba(174, 110, 15, 0.16);
  --theme-quipuswap-badge-text: #f9a558;
  --theme-table-header-hover-bg: #f7f5fb;
  --theme-button-bg: #e3d1ff;
  --theme-hover-bg: #f2f0f9;
  --theme-radio-button: #8b83ba;
  --theme-governance-bg: linear-gradient(180deg, #7028e4 22%, #ffffff 20%);

  --theme-governance-bg-1: linear-gradient(180deg, #7028e4 20%, #ffffff 20%);
  --theme-governance-bg-2: linear-gradient(180deg, #7028e4 18%, #ffffff 18%);
  --theme-governance-bg-2-1: linear-gradient(180deg, #7028e4 15%, #ffffff 15%);
  --theme-governance-text: #04091b;
  --theme-governance-cardBg: #ffffff;
  --theme-governance-postingInfo: #f3f4fb;
  --theme-governance-line: #f1f1f1;
  --theme-governance-headerBg: #7028e4;
  --theme-governance-mobileBg: linear-gradient(180deg, #7028e4 30%, #ffffff 30%);
  --theme-governance-mobileHeaderBg: #7028e4;
  --theme-governance-infoText: rgba(51, 51, 51, 0.8);
  --theme-governance-infoBg: #f4f4f4;
  --theme-governance-votingBorder: none;
  --theme-governance-buttonBorder: #6202ef;
  --theme-governance-infoIcon: rgba(90, 90, 90, 0.1);
  --theme-governance-totalVotesText: #4e5d78;
  --theme-governance-infoIconColor: #5a5a5a;
  --theme-governance-clockIconColor: #374151;
  --theme-governance-openBg: #01d1ff;
  --theme-governance-openText: #302000;
  --theme-governance-acceptBg: rgba(22, 235, 147, 0.2);
  --theme-governance-acceptText: #066d43;
  --theme-governance-rejectBg: rgba(255, 2, 2, 0.2);
  --theme-governance-rejectText: #461303;
  --theme-governance-votingBoxBg: rgba(98, 2, 239, 0.1);
  --theme-governance-selectedText: #40029d;
  --theme-governance-label: #04091b;
  --theme-header-heading: #000000;
  --theme-header-description: #51566b;
  --theme-header-bottom: #dedede;
  --theme-header-text: #333333;
  --theme-header-selectedBg: #f4f4f4;
  --theme-bg-header: #ffffff;
  --theme-header-seperator: #140920;
  --theme-expand-button-bg: var(--theme-input-bg);
  --theme-primary-button-text: #ffffff;
  --theme-primary-button-bg: #6202ef;
  --theme-menu-highlighter: #cfced6;
  --theme-menu-text: #9656f4;
  --theme-disabled-button-bg: #e6e7ea;
  --theme-disabled-button-text: #8a94a6;
  --theme-modal-boxshadow: 0px 16.9px 32.3304px rgba(149, 165, 192, 0.14);
  --theme-thead-text: var(--theme-text-3);
  --theme-toggler: #ffffff;
  --theme-toggler-bg: #c1c7d0;
  --theme-disabled-button: #b0b7c3;
  --theme-disable-button-text: #fafafb;
  --theme-swap-border: rgba(0, 0, 0, 0.08);
  --theme-swap-text: #505e79;
  --theme-swap-info-text: #505e79;
  --theme-gradient-border: #e7e7e7;
  --theme-swap-boxshadow: 0px 16.9px 32.3304px rgba(149, 165, 192, 0.14);
  --theme-error-bg: rgba(255, 90, 67, 0.05);
  --theme-banner-bg: #f2e9ff;
  --theme-banner-bg-hover: #eadcff;
  --theme-banner-text: #201c41;
  --theme-confirm-transaction: #4e5d78;
  --theme-confirm-transaction-button-text: #201c41;
  --theme-loader-border-top: rgba(255, 255, 255, 0.2);
  --theme-loader-border: #ffffff;
  --theme-flash-bg: #ffffff;
  --theme-flash-border: #b0b7c3;
  --theme-floater-text: #333333;
  --theme-nav-link-border: #f1f1f1;
  --theme-input-bg-global: #f9fafa;
  --theme-balance-border: #a4aebf;
  --theme-balance-bg: #f9fafa;
  --theme-balance-text-color: rgb(138, 148, 166);
  --theme-lq-details-bg: #ffffff;
  --theme-lq-details-individual-bg: #f9fafa;
  --theme-lq-details-content: rgba(51, 51, 51, 0.6);
  --theme-info-icon: #b0b7c3;
  --theme-lp-pair-bg: rgba(98, 2, 239, 0.04);
  --theme-lp-pair-text: rgba(51, 51, 51, 0.8);
  --theme-share-pool-bg: rgba(98, 2, 239, 0.04);
  --theme-confirm-supply-text: #505e79;
  --theme-remove-lq: #ffffff;
  --theme-your-position: #ffffff;
  --theme-details-border-left: #f3f3f3;
  --theme-lp-token: rgba(78, 93, 120, 0.8);
  --theme-modal-text: #8a94a6;
  --theme-tooltip-bg: #0d0831;
  --theme-tooltip-text: #ffffff;
  --theme-bridge-from-ip-bg: #fcfcfc;
  --theme-bridge-to-ip-bg: rgba(98, 2, 239, 0.05);
  --theme-confirm-transaction-border: rgba(78, 93, 120, 0.5);
  --theme-banner-close-bg: #e6d4ff;
  --theme-banner-close-icon: #6202ef;
  --theme-banner-close-bg-hover: #a87bea;
  --theme-banner-close-icon-hover: #ffffff;
  --theme-swap-revamp-second-bg: #f7f2ff;
  --theme-swap-revamp-second-border: #eadcff;
  --theme-swap-second-input-bg: rgba(255, 255, 255, 0.8);
  --theme-swap-disable-button: #d1beed;
  --theme-swap-details-bg: #ffffff;
  --theme-swap-details-heading: #333333;
  --theme-swap-revamp-firstborder-typing: rgba(0, 0, 0, 0.08);
  --theme-swap-revamp-token-selector: #ffffff;
  --theme-swap-revamp-token-selector-border: rgba(176, 183, 195, 0.5);
  --theme-settings-border: #b0b7c3;
  --theme-not-selected-token: #ffffff;
  --theme-receive-heading: #45138d;
  --theme-not-selected-border: rgba(98, 2, 239, 0.4);
  --theme-default-swap-bg: #fefefe;
  --theme-hover-first-swap-border: rgba(0, 0, 0, 0.2);
  --theme-hover-token-selector-border: rgba(98, 2, 239, 0.4);
  --theme-hover-token-selector-bg: #f9fafa;
  --theme-typing-first-swap: rgba(98, 2, 239, 0.4);
  --theme-typing-first-swap-bg: #ffffff;
  --theme-route-bg: #fdfcff;
  --theme-route-border: rgba(176, 183, 195, 0.4);
  --theme-stableswap-route-border: rgba(98, 2, 239, 0.4);
  --theme-arrow-boxshadow: rgba(32, 28, 65, 0.32);
  --theme-liq-divider: #b0b7c3;
  --theme-balance-border-second: #a4aebf;
  --theme-balance-onhover-second: #4e5d78;
  --theme-hover-liq-input: rgba(0, 0, 0, 0.2);
  --theme-second-swap-input-typing: rgba(98, 2, 239, 0.3);
  --theme-second-swap-input: rgba(98, 2, 239, 0.2);
  --theme-swap-hr: #b39dd3;
}
:root.dark {
  --theme-primary-1: #ffffff;
  --theme-primary-2: #8a94a6;
  --theme-secondary-1: #e3d1ff;
  --theme-primary-gradient-1: #7028e4;
  --theme-primary-gradient-2: #e5b2ca;
  --theme-secondary-gradient-1: #3a537b;
  --theme-secondary-gradient-2: #8f9aac;
  --theme-secondary-gradient: #343052;
  --theme-card-bg: #201c41;
  --theme-input-bg: #343052;
  --theme-text-1: #ffffff;
  --theme-text-inactive: #b0b7c3; // TODO find
  --theme-text-2: #ffffff;
  --theme-text-3: #ceaefe;
  --theme-text-4: #b0b7c3; // TODO find
  --theme-text-5: #4e5d78;
  --theme-bg-1: #0d0831;
  --theme-bg-2: #343052;
  --theme-bg-3: #fafbfc; // TODO find
  --theme-bg-4: #343052;
  --theme-border: #4e576c;
  --theme-border-global: rgba(78, 87, 108, 0.4);
  --theme-border-light: #6a7695;
  --theme-default-btn: #ffffff;
  --theme-plenty-badge-bg: rgba(208, 189, 230, 0.2);
  --theme-plenty-badge-text: #ceaefe;
  --theme-quipuswap-badge-bg: rgba(249, 165, 88, 0.14);
  --theme-quipuswap-badge-text: #f9a558;
  --theme-table-header-hover-bg: #343052;
  --theme-button-bg: #fafbfc;
  --theme-hover-bg: #59617a;
  --theme-radio-button: #ffffff;
  --theme-governance-bg: linear-gradient(180deg, var(--theme-bg-1) 22%, #393456 20.85%);

  --theme-governance-bg-1: linear-gradient(180deg, var(--theme-bg-1) 20%, #393456 18.85%);
  --theme-governance-bg-2: linear-gradient(180deg, var(--theme-bg-1) 18%, #393456 17.85%);
  --theme-governance-bg-2-1: linear-gradient(180deg, var(--theme-bg-1) 15%, #393456 15%);
  --theme-governance-text: #edecef;
  --theme-governance-cardBg: #201c41;
  --theme-governance-postingInfo: rgba(255, 255, 255, 0.06);
  --theme-governance-line: rgba(241, 241, 241, 0.1);
  --theme-governance-mobileBg: linear-gradient(90.61deg, #393456 35.85%, #82858a 135.76%);
  --theme-governance-mobileHeaderBg: linear-gradient(90.61deg, #04152e 35.85%, #82858a 135.76%);
  --theme-governance-headerBg: #333e5b;
  --theme-governance-infoText: rgba(237, 236, 239, 0.7);
  --theme-governance-infoBg: rgba(244, 244, 244, 0.1);
  --theme-governance-votingBorder: linear-gradient(
    100.44deg,
    #f8b67f 0%,
    #ff5451 33.57%,
    #ee1ec0 67.02%,
    #a02cfc 103.97%
  );
  --theme-governance-buttonBorder: #ceaefe;
  --theme-governance-infoIcon: rgba(237, 236, 239, 0.1);
  --theme-governance-totalVotesText: #8a94a6;
  --theme-governance-infoIconColor: #ffffff;
  --theme-governance-clockIconColor: #ffffff;
  --theme-governance-openBg: rgba(1, 209, 255, 0.2);
  --theme-governance-openText: #01d1ff;
  --theme-governance-acceptBg: rgba(22, 235, 147, 0.3);
  --theme-governance-acceptText: #2fffa9;
  --theme-governance-rejectBg: rgba(214, 101, 101, 0.3);
  --theme-governance-rejectText: #ff5353;
  --theme-governance-votingBoxBg: rgb(230, 214, 254, 0.18);
  --theme-governance-selectedText: #ffffff;
  --theme-governance-label: rgba(255, 255, 255, 0.6);
  --theme-header-heading: #ffffff;
  --theme-header-description: #edecef;
  --theme-header-bottom: #393456;
  --theme-header-text: #ffffff;
  --theme-header-selectedBg: #0d0831;
  --theme-bg-header: #201c41;
  --theme-header-seperator: #f4f4f4;
  --theme-expand-button-bg: #343052;
  --theme-primary-button-text: #0d0831;
  --theme-primary-button-bg: #ceaefe;
  --theme-menu-highlighter: #524666;
  --theme-menu-text: #dec9fe;
  --theme-disabled-button-bg: #8974a9;
  --theme-disabled-button-text: #353153;
  --theme-modal-boxshadow: 0px 2px 82px 1px rgba(22, 19, 37, 0.65);
  --theme-balance-bg: #211c49;
  --theme-thead-text: #edecef;
  --theme-toggler: #6202ef;
  --theme-toggler-bg: #343052;
  --theme-disabled-button: #564f80;
  --theme-disable-button-text: #0e0931;
  --theme-swap-border: rgba(81, 75, 107, 0.6);
  --theme-swap-text: #ffffff;
  --theme-swap-info-text: #f3f4fb;
  --theme-gradient-border: #393456;
  --theme-swap-boxshadow: 0px 2px 49px rgba(34, 6, 73, 0.39);
  --theme-error-bg: rgba(255, 90, 67, 0.05);
  --theme-banner-bg: rgba(206, 174, 254, 0.07);
  --theme-banner-bg-hover: rgba(206, 174, 254, 0.18);
  --theme-banner-text: #ffffff;
  --theme-confirm-transaction: #b0b7c3;
  --theme-confirm-transaction-button-text: #f9fafa;
  --theme-loader-border: #1c1357;
  --theme-loader-border-top: rgba(254, 253, 255, 0.6);
  --theme-flash-bg: rgba(18, 11, 67, 1);
  --theme-flash-border: #4e5d78;
  --theme-floater-text: #ffffff;
  --theme-nav-link-border: rgba(255, 255, 255, 0.15);
  --theme-input-bg-global: #211c49;
  --theme-balance-border: rgba(115, 122, 134, 0.5);
  --theme-balance-text-color: rgba(176, 183, 195, 0.5);
  --theme-lq-details-bg: #221f43;
  --theme-lq-details-individual-bg: #2d2a51;
  --theme-lq-details-content: rgba(138, 148, 166, 0.6);
  --theme-info-icon: #ccd2e3;
  --theme-lp-pair-bg: rgba(206, 174, 254, 0.05);
  --theme-lp-pair-text: #f9fafa;
  --theme-share-pool-bg: rgba(206, 174, 254, 0.2);
  --theme-confirm-supply-text: #b0b7c3;
  --theme-remove-lq: #2d2856;
  --theme-your-position: #211c49;
  --theme-details-border-left: rgba(206, 174, 254, 0.15);
  --theme-lp-token: #b0b7c3;
  --theme-modal-text: #b0b7c3;
  --theme-tooltip-bg: #ffffff;
  --theme-tooltip-text: #0d0831;
  --theme-bridge-from-ip-bg: rgba(138, 149, 158, 0.05);
  --theme-bridge-to-ip-bg: rgba(206, 174, 254, 0.05);
  --theme-confirm-transaction-border: rgba(138, 149, 158, 0.4);
  --theme-banner-close-bg: rgba(126, 134, 158, 0.1);
  --theme-banner-close-icon: #ffffff;
  --theme-banner-close-bg-hover: rgba(126, 134, 158, 0.1);
  --theme-banner-close-icon-hover: #ffffff;
  --theme-swap-revamp-second-bg: rgba(206, 174, 254, 0.07);
  --theme-swap-revamp-second-border: #403868;
  --theme-swap-second-input-bg: rgba(206, 174, 254, 0.05);
  --theme-swap-disable-button: #6a5688;
  --theme-swap-details-bg: rgba(159, 95, 255, 0.08);
  --theme-swap-details-heading: #dfdfdf;
  --theme-swap-revamp-firstborder-typing: rgba(98, 104, 143, 0.4);
  --theme-swap-revamp-token-selector: #28234d;
  --theme-swap-revamp-token-selector-border: #62688f;
  --theme-settings-border: #8a92b2;
  --theme-not-selected-token: rgba(206, 174, 254, 0.06);
  --theme-receive-heading: #8f72bb;
  --theme-not-selected-border: #ceaefe;
  --theme-default-swap-bg: rgba(138, 149, 158, 0.05);
  --theme-hover-first-swap-border: rgba(98, 104, 143, 0.8);
  --theme-hover-token-selector-bg: #28234d;
  --theme-hover-token-selector-border: rgba(206, 174, 254, 0.4);
  --theme-typing-first-swap: rgba(206, 174, 254, 0.4);
  --theme-typing-first-swap-bg: rgba(138, 149, 158, 0.05);
  --theme-route-bg: #28234d;
  --theme-route-border: rgba(98, 104, 143, 0.5);
  --theme-stableswap-route-border: rgba(98, 104, 143, 0.5);
  --theme-arrow-boxshadow: rgba(32, 28, 65, 0.32);
  --theme-liq-divider: rgba(176, 183, 195, 0.3);
  --theme-balance-border-second: rgba(176, 183, 195, 0.5);
  --theme-balance-onhover-second: #b0b7c3;
  --theme-hover-liq-input: rgba(98, 104, 143, 0.8);
  --theme-second-swap-input-typing: rgba(206, 174, 254, 0.2);
  --theme-second-swap-input: rgba(206, 174, 254, 0.2);
  --theme-swap-hr: rgba(255, 255, 255, 0.15);
}

:root.theme-transiton {
  transition: 1000ms !important;
  transition-delay: 0s !important;
}
